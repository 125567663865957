/* eslint-disable no-use-before-define */
import { TaskSummaryDto } from '@cloudmed/resolution-services-api-client';
import { TaskDataUi } from '../../routes/tasks-worker/shared/models/tasks-worker.model';
import { GetTasksParams, GetTasksParamsOriginal } from '../models/tasks.model';

export function convertTaskDtoTo(task: TaskSummaryDto): TaskDataUi {
  return {
    ...task,
    patientName: getPatientName(task),
  };
}

function getPatientName(task: TaskSummaryDto): string {
  return `${task.patientLastName} ${task.patientFirstName}`;
}

export function convertToGetTasksParams(
  params: GetTasksParams
): GetTasksParamsOriginal {
  return [
    params.limit,
    params.continuationToken,
    params.status,
    params.assignedTo,
    params.completedBy,
    params.sort,
    params.completedSince,
  ];
}
